import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1128.000000 851.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,851.000000) scale(0.100000,-0.100000)" >
<path d="M5914 7341 c-50 -11 -136 -38 -193 -61 -89 -37 -108 -41 -150 -35
-43 6 -49 4 -54 -14 -3 -12 1 -28 9 -35 8 -8 14 -16 14 -19 0 -2 -22 -28 -50
-58 -92 -101 -140 -231 -140 -384 1 -258 112 -429 429 -659 l41 -30 0 -121 c0
-133 -13 -256 -36 -355 -20 -81 -74 -202 -88 -197 -21 7 -76 111 -106 201 -34
102 -55 209 -65 336 -4 47 -12 91 -17 98 -7 7 -36 12 -78 12 -62 0 -68 -2 -78
-25 -9 -18 -12 -142 -12 -418 0 -216 -4 -398 -9 -406 -14 -22 -163 -9 -222 18
-90 42 -141 112 -176 241 -17 60 -18 127 -18 850 0 646 2 791 14 818 20 49 62
73 147 85 70 10 74 12 74 36 l0 26 -535 0 -535 0 0 -26 c0 -25 4 -27 58 -33
67 -8 118 -35 135 -74 8 -20 13 -211 18 -707 7 -703 11 -781 53 -916 44 -143
144 -265 272 -332 147 -76 382 -114 608 -97 88 6 112 5 124 -6 8 -9 35 -14 71
-14 63 0 91 18 91 58 0 14 11 27 29 37 28 14 33 13 103 -20 158 -73 317 -99
565 -93 208 5 316 27 452 90 264 121 392 321 378 590 -12 246 -118 412 -397
619 -41 31 -138 100 -215 154 -346 242 -404 308 -405 463 0 85 14 125 61 169
75 70 232 83 343 29 129 -63 245 -229 295 -421 6 -22 16 -76 22 -120 13 -98
26 -115 89 -115 31 0 55 6 68 18 20 16 21 34 30 402 11 451 15 430 -78 430
-50 0 -61 -4 -74 -22 -9 -12 -16 -34 -16 -48 0 -15 -6 -34 -12 -43 -12 -15
-24 -11 -133 43 -153 76 -221 90 -440 96 -139 3 -187 0 -261 -15z m88 -1421
c113 -78 247 -209 278 -270 93 -182 53 -352 -98 -411 -46 -18 -77 -22 -157
-22 -110 0 -189 23 -245 70 l-30 24 30 57 c62 122 94 269 105 495 4 70 11 127
16 125 5 -2 50 -32 101 -68z"/>
<path d="M6443 4659 c-135 -26 -203 -108 -211 -256 -8 -152 31 -231 186 -378
103 -98 142 -161 142 -230 0 -58 -22 -85 -70 -85 -50 0 -60 18 -60 110 l0 71
-102 -3 -103 -3 0 -75 c1 -98 19 -160 62 -210 56 -65 102 -84 208 -85 81 0 95
3 143 29 95 53 138 151 130 294 -7 122 -45 190 -179 321 -61 58 -118 124 -129
147 -41 84 -14 175 48 159 44 -11 51 -23 54 -93 l3 -67 100 0 100 0 -1 80 c-2
165 -83 262 -229 278 -27 2 -69 1 -92 -4z"/>
<path d="M3372 4183 c3 -464 3 -468 26 -518 28 -61 64 -100 117 -128 33 -18
57 -21 135 -21 90 0 98 1 145 31 28 18 60 48 73 67 50 74 52 97 52 583 l0 453
-105 0 -105 0 0 -451 c0 -291 -4 -457 -10 -470 -8 -14 -21 -19 -50 -19 -29 0
-42 5 -50 19 -6 13 -10 179 -10 470 l0 451 -110 0 -111 0 3 -467z"/>
<path d="M4000 4091 l0 -560 85 -3 c47 -2 90 -1 96 1 10 3 9 57 -6 596 -2 88
-2 156 0 150 3 -5 25 -172 50 -370 25 -198 47 -364 49 -370 2 -6 38 -9 89 -7
70 3 87 7 91 20 3 9 23 161 46 337 22 176 42 329 44 340 3 11 2 -138 0 -332
-3 -194 -3 -356 -1 -360 3 -4 48 -7 101 -5 l96 3 0 560 0 559 -139 0 -140 0
-6 -42 c-10 -78 -65 -552 -71 -625 -4 -40 -10 -73 -13 -73 -4 0 -13 53 -20
118 -7 64 -26 227 -42 362 -16 135 -29 248 -29 253 0 4 -63 7 -140 7 l-140 0
0 -559z"/>
<path d="M4817 4644 c-3 -4 -6 -256 -6 -560 l-1 -554 101 0 100 0 -5 118 c-8
160 -15 631 -10 612 2 -8 24 -175 49 -370 25 -195 47 -356 49 -358 2 -1 41 -4
86 -5 75 -2 83 0 91 18 4 11 24 153 44 315 21 162 41 318 45 345 5 28 6 -113
3 -312 l-6 -363 102 0 101 0 0 560 0 560 -140 0 c-162 0 -142 18 -160 -150
-19 -181 -61 -533 -67 -570 -8 -44 -6 -61 -48 315 -19 171 -38 331 -41 358
l-7 47 -137 0 c-75 0 -139 -3 -143 -6z"/>
<path d="M5760 4626 c0 -14 -31 -215 -69 -448 -101 -618 -104 -638 -100 -642
4 -4 149 -9 185 -7 7 1 17 37 24 84 19 139 10 127 105 127 l82 0 7 -52 c3 -29
6 -77 6 -105 l0 -53 85 -1 c47 -1 95 -2 108 -2 16 -1 22 4 20 19 -2 20 -85
536 -108 669 -7 44 -26 160 -42 258 l-28 177 -137 0 -138 0 0 -24z m155 -406
c9 -85 19 -185 22 -222 l6 -68 -49 0 -50 0 12 118 c27 259 37 347 40 337 2 -5
10 -80 19 -165z"/>
<path d="M5892 4005 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M6810 4202 c0 -485 3 -517 54 -590 15 -22 50 -52 79 -68 47 -26 61
-29 142 -29 72 1 98 5 130 22 50 26 86 64 116 123 l24 45 3 473 3 472 -106 0
-105 0 0 -454 c0 -503 2 -486 -64 -486 -62 0 -61 -11 -64 487 l-3 453 -104 0
-105 0 0 -448z"/>
<path d="M7437 4643 c-4 -3 -7 -255 -7 -560 l0 -553 183 0 c207 0 255 10 307
63 66 67 75 97 75 257 0 166 -12 206 -78 249 l-37 24 29 18 c51 31 71 91 71
210 0 132 -12 173 -64 225 -61 62 -114 74 -311 74 -89 0 -165 -3 -168 -7z
m313 -203 c11 -7 16 -33 18 -99 4 -80 2 -91 -17 -110 -14 -14 -34 -21 -61 -21
l-40 0 0 120 0 120 43 0 c23 0 49 -5 57 -10z m16 -451 c16 -18 19 -37 19 -130
0 -71 -4 -112 -12 -120 -7 -7 -37 -14 -68 -17 l-55 -4 0 146 0 146 48 0 c37 0
54 -5 68 -21z"/>
<path d="M7689 4408 c-10 -35 -6 -46 16 -51 30 -5 36 2 28 31 -3 13 -13 22
-24 22 -11 0 -19 -1 -20 -2z"/>
<path d="M7683 3946 c0 -18 5 -26 17 -26 10 0 26 -3 35 -6 13 -6 16 -1 13 22
-2 24 -8 29 -34 32 -28 3 -31 0 -31 -22z"/>
<path d="M6480 4400 c0 -25 4 -30 25 -30 15 0 25 6 25 14 0 28 -12 46 -31 46
-14 0 -19 -7 -19 -30z"/>
<path d="M6195 4255 c10 -84 55 -165 140 -254 44 -46 86 -81 97 -81 10 0 20
-6 22 -12 7 -24 17 -43 41 -83 21 -34 25 -36 25 -17 0 48 -33 98 -134 196
-113 110 -144 154 -176 243 l-21 58 6 -50z"/>
<path d="M6530 4271 c0 -3 12 -18 27 -33 26 -27 31 -28 120 -28 l93 0 0 29 0
30 -120 3 c-66 2 -120 1 -120 -1z"/>
<path d="M8011 4239 c0 -8 -12 -38 -26 -65 -29 -56 -30 -64 -11 -64 16 0 50
101 43 127 -4 14 -5 14 -6 2z"/>
<path d="M3946 3698 c-15 -80 -73 -155 -151 -196 -44 -23 -62 -26 -145 -27
-103 0 -157 17 -211 66 -33 30 -74 96 -88 140 -10 32 -11 32 -6 -13 9 -90 70
-178 158 -225 25 -14 56 -18 142 -18 103 0 113 2 163 29 78 43 121 106 142
209 6 32 10 61 7 63 -2 3 -7 -10 -11 -28z"/>
<path d="M8016 3668 c-11 -32 -35 -68 -66 -99 -72 -72 -112 -81 -354 -77 -194
3 -196 3 -196 -18 0 -43 17 -46 227 -42 221 5 242 10 314 75 46 42 74 91 89
158 14 64 8 65 -14 3z"/>
<path d="M7381 3694 c-2 -33 -49 -119 -80 -147 -63 -56 -109 -71 -211 -72 -76
0 -104 4 -141 21 -61 28 -111 74 -135 123 -10 23 -21 41 -24 41 -3 0 -14 -18
-24 -41 -74 -153 -322 -197 -470 -84 -45 34 -46 34 -46 12 0 -26 48 -76 100
-103 54 -29 240 -27 296 3 45 24 104 85 129 133 l17 32 19 -39 c28 -57 76
-102 137 -131 48 -22 64 -24 150 -20 115 5 159 24 219 92 42 48 78 138 70 178
-4 20 -4 21 -6 2z"/>
<path d="M5845 3659 c-4 -22 -4 -43 -1 -46 3 -4 6 1 6 10 0 14 10 17 55 17 54
0 55 0 55 30 0 30 -1 30 -54 30 l-54 0 -7 -41z"/>
<path d="M5832 3570 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5311 3544 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4489 3525 l-4 -30 -115 -3 c-99 -2 -116 0 -127 15 -12 15 -13 15
-13 0 0 -14 -16 -16 -135 -14 -130 2 -135 1 -135 -18 0 -39 19 -45 129 -45
111 0 121 3 139 35 11 20 11 20 12 -1 0 -30 16 -34 129 -34 102 0 121 7 121
45 1 15 4 12 15 -12 l14 -33 119 0 c78 0 122 4 130 12 9 9 15 9 24 0 13 -13
202 -17 232 -6 9 3 16 18 16 33 l0 26 -138 -2 c-75 -1 -193 -2 -260 -3 -68 -1
-128 2 -132 6 -5 5 -10 20 -12 34 -4 24 -5 23 -9 -5z"/>
<path d="M5050 3493 c0 -54 18 -63 135 -63 93 0 105 2 115 19 5 11 10 30 9 43
0 13 -3 17 -6 11 -3 -9 -37 -13 -118 -13 -98 0 -115 2 -124 18 -9 14 -10 12
-11 -15z"/>
<path d="M5816 3505 c-5 -14 -38 -15 -251 -13 -241 3 -245 3 -245 -17 0 -43
13 -45 249 -45 125 0 232 4 239 8 7 5 15 25 19 45 6 38 0 49 -11 22z"/>
<path d="M5966 3475 c14 -44 21 -46 153 -43 l126 3 3 30 3 30 -145 0 c-141 0
-146 -1 -140 -20z"/>
<path d="M5113 3149 c-18 -5 -49 -26 -69 -46 -34 -34 -36 -39 -32 -88 7 -87
39 -116 168 -156 50 -16 64 -44 34 -66 -25 -18 -53 -16 -99 7 -41 21 -41 21
-89 -22 -19 -18 -19 -18 9 -48 15 -16 50 -37 77 -46 65 -21 135 -6 189 43 45
40 57 86 40 143 -15 50 -48 75 -131 100 -79 23 -97 39 -80 69 13 26 75 29 108
5 21 -15 25 -14 57 11 19 14 35 30 35 35 0 13 -49 45 -89 58 -40 14 -83 14
-128 1z"/>
<path d="M6930 3150 c-72 -13 -119 -49 -139 -109 -14 -42 -14 -203 -1 -253 22
-77 132 -132 219 -108 40 11 121 72 121 92 0 5 -21 17 -46 28 l-45 20 -29 -21
c-21 -16 -36 -20 -57 -14 -50 12 -53 20 -53 131 0 95 2 104 22 118 30 21 57
20 92 -3 26 -17 31 -18 65 -4 53 22 55 27 25 62 -43 52 -104 73 -174 61z"/>
<path d="M7648 3151 c-130 -42 -146 -197 -27 -257 24 -12 64 -28 89 -36 50
-14 65 -43 34 -65 -25 -18 -53 -16 -99 7 l-39 20 -25 -23 c-40 -35 -42 -40
-18 -65 48 -52 119 -69 194 -47 60 18 99 54 115 106 25 86 -26 154 -137 180
-60 14 -90 42 -75 70 13 24 71 26 105 3 24 -16 27 -15 60 7 19 13 35 30 35 36
0 7 -18 24 -39 38 -42 29 -126 41 -173 26z"/>
<path d="M3390 2914 l0 -236 125 4 c119 4 126 5 167 34 56 39 71 91 65 225 -5
117 -22 154 -87 187 -38 19 -60 22 -157 22 l-113 0 0 -236z m224 110 c12 -12
16 -37 16 -106 0 -110 -11 -128 -77 -128 l-43 0 0 125 0 125 44 0 c27 0 51 -6
60 -16z"/>
<path d="M3910 3147 c0 -4 -144 -419 -156 -450 -6 -16 -1 -18 56 -15 l62 3 17
45 16 45 62 3 61 3 17 -51 17 -50 59 0 c32 0 59 4 59 10 0 5 -37 111 -81 235
l-82 225 -53 0 c-30 0 -54 -1 -54 -3z m74 -184 c3 -21 9 -48 12 -60 6 -21 3
-23 -31 -23 -35 0 -37 1 -31 28 28 113 38 125 50 55z"/>
<path d="M4130 3095 l0 -55 60 0 60 0 0 -180 0 -180 60 0 60 0 0 180 0 180 60
0 60 0 0 55 0 55 -180 0 -180 0 0 -55z"/>
<path d="M4527 2927 c-42 -123 -77 -229 -77 -235 0 -8 20 -12 58 -12 l58 0 15
48 15 47 64 0 64 0 15 -47 15 -48 62 0 63 0 -46 128 c-25 70 -62 174 -82 232
l-37 105 -55 3 -55 3 -77 -224z m151 30 c17 -73 16 -77 -18 -77 -19 0 -30 5
-30 14 0 25 23 116 29 116 4 0 12 -24 19 -53z"/>
<path d="M5390 2915 l0 -235 163 2 162 3 3 53 3 52 -106 0 -106 0 3 38 3 37
93 3 93 3 -3 47 -3 47 -92 3 -93 3 0 34 0 34 103 3 102 3 3 53 3 52 -165 0
-166 0 0 -235z"/>
<path d="M5780 2915 l0 -235 55 0 55 0 0 75 c0 56 3 75 13 75 8 0 38 -34 68
-75 l54 -74 68 -1 c37 0 67 3 67 8 0 4 -25 38 -55 75 -30 38 -55 72 -55 76 0
4 11 14 25 21 33 17 56 70 56 128 1 57 -11 90 -42 119 -39 35 -74 43 -195 43
l-114 0 0 -235z m230 106 c28 -53 -4 -94 -71 -89 l-44 3 -3 53 -3 52 55 0 c44
0 57 -4 66 -19z"/>
<path d="M6140 3141 c0 -10 76 -227 132 -378 l31 -83 52 0 c42 0 54 4 58 18 3
9 39 111 81 227 42 115 76 213 76 217 0 5 -27 8 -60 8 l-59 0 -40 -127 c-22
-71 -43 -145 -47 -166 -7 -41 -24 -34 -24 11 0 12 -19 81 -42 152 l-42 130
-58 0 c-32 0 -58 -4 -58 -9z"/>
<path d="M6610 2915 l0 -236 58 3 57 3 3 233 2 232 -60 0 -60 0 0 -235z"/>
<path d="M7170 2936 c1 -118 4 -224 7 -235 5 -20 12 -21 164 -21 l159 0 0 55
0 55 -106 0 -105 0 3 38 3 37 93 3 92 3 0 49 0 50 -95 0 -95 0 0 35 0 35 105
0 105 0 0 55 0 55 -165 0 -166 0 1 -214z"/>
<path d="M5492 2430 c-19 -32 -14 -57 21 -96 42 -49 144 -95 223 -102 100 -8
251 47 293 107 24 34 27 83 6 101 -19 16 -31 7 -59 -48 -13 -26 -37 -60 -54
-75 -31 -30 -113 -57 -175 -57 -73 0 -156 53 -183 118 -15 36 -41 72 -53 72
-3 0 -12 -9 -19 -20z"/>
<path d="M5130 2345 c-10 -12 -11 -24 -4 -43 18 -54 144 -92 358 -108 92 -6
96 -6 96 13 0 18 -9 21 -76 26 -109 10 -185 32 -279 83 -73 40 -84 43 -95 29z"/>
<path d="M6365 2353 c-11 -3 -33 -16 -50 -28 -51 -39 -156 -77 -235 -86 -104
-12 -130 -19 -130 -34 0 -20 11 -21 133 -13 121 9 219 34 279 74 37 23 57 62
43 83 -7 12 -8 12 -40 4z"/>
<path d="M4985 2195 c-5 -2 -23 -6 -38 -9 -23 -6 -28 -11 -25 -33 5 -46 21
-48 225 -35 194 12 323 29 323 43 0 11 -58 18 -245 29 -173 10 -222 11 -240 5z"/>
<path d="M6290 2189 c-162 -10 -231 -19 -226 -28 12 -19 273 -51 411 -51 89 0
104 3 120 20 9 10 15 26 11 35 -13 36 -66 40 -316 24z"/>
<path d="M5661 2176 c-18 -21 12 -78 37 -69 30 11 41 34 29 60 -12 26 -48 31
-66 9z"/>
<path d="M5807 2172 c-16 -17 -16 -20 4 -41 23 -25 47 -24 63 2 25 39 -35 75
-67 39z"/>
<path d="M2652 2173 c-28 -3 -42 -8 -39 -16 3 -9 272 -13 1135 -15 623 -1
1132 2 1132 6 0 31 -21 32 -1104 30 -595 -1 -1100 -3 -1124 -5z"/>
<path d="M6663 2173 c-7 -2 -13 -11 -13 -19 0 -8 6 -14 13 -14 6 -1 511 0
1121 0 848 0 1113 3 1124 13 12 10 11 13 -9 18 -28 8 -2218 10 -2236 2z"/>
<path d="M5388 2101 c-128 -23 -229 -55 -253 -81 -12 -13 -15 -27 -11 -46 6
-23 11 -26 37 -20 17 3 42 15 55 25 52 41 248 100 331 101 30 0 52 15 39 27
-13 13 -100 11 -198 -6z"/>
<path d="M5945 2109 c-9 -14 -10 -14 75 -29 130 -22 315 -88 335 -120 9 -15
41 -12 54 4 42 50 -87 111 -294 141 -120 18 -162 19 -170 4z"/>
<path d="M5745 2072 c-6 -5 -37 -14 -70 -19 -96 -17 -131 -36 -165 -89 -33
-53 -38 -89 -14 -98 32 -12 52 3 59 45 15 88 120 137 254 118 110 -17 151 -47
165 -121 7 -39 22 -50 55 -41 30 8 27 46 -10 99 -33 49 -62 64 -180 94 -90 23
-82 22 -94 12z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
